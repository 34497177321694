// import phone from "../assets/images/icons/phone-icon.png";
import { motion } from 'framer-motion'
import { useQuery, gql } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { addGame } from './dashboard/gameSlice'
import { combinations } from 'mathjs'
import moment from 'moment'
import AmountModal from '../components/AmountModal'

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const GET_TODAY_GAME = gql`
  query gameOfTheDay($schedule_day: jsonb) {
    game(
      limit: 1
      order_by: [{ schedule_start: asc }, { run_end: asc }]
      where: {
        is_active: { _eq: true }
        schedule_start: { _lte: now }
        schedule_end: { _gte: now }
        product: { schedule_day: { _contains: $schedule_day } }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      run_start
      run_end
      event_code
      claim_expiry_hours
      is_active
      product {
        id
        name
        image
        description
        slogan
        min_stake_amount
        max_stake_amount
        schedule_start
        schedule_end
        schedule_day
        schedule_type
        is_active
        properties
      }
    }
  }
`

const GET_USER_TYPE = gql`
  query getUserType($_userID: uuid) {
    customer(where: { id: { _eq: $_userID } }) {
      user_type
    }
  }
`

let d = moment().weekday()
let todayDate =
  d === 2
    ? 'tue'
    : d === 3
    ? 'wed'
    : d === 4
    ? 'thu'
    : d === 5
    ? 'fri'
    : d === 6
    ? 'sat'
    : d === 0
    ? 'sun'
    : 'mon'

const GamesFive = () => {
  const [selectedNumbers, setSelectedNumbers] = useState([])
  const [amount, setAmount] = useState(0.0)
  const [isOpen, setIsOpen] = useState(false)
  const images = JSON.parse(localStorage.getItem('images'))
  const [userType, setUserType] = useState(null)

  // console.log(`${browserName} ${browserVersion}`)

  const { data } = useQuery(GET_TODAY_GAME, {
    variables: {
      schedule_day: [todayDate]
    }
  })

  const { data: customer } = useQuery(GET_USER_TYPE, {
    variables: {
      _userID: localStorage.getItem('customer_id')
    }
  })

  useEffect(() => {
    if (customer) {
      setUserType(customer[0]?.user_type)
    }
  }, [userType, customer])

  const gameImage = images?.data?.find(
    element => element.file_name === data?.game[0]?.product.image
  )

  const { id } = useParams()
  // console.log(id);
  const properties = data?.game[0].product.properties.digit_options.filter(
    item => item.id === +id
  )

  const numbers = () => {
    const array = Array(96)
      .fill(1)
      .map((n, i) => n + i)

    return array
  }

  // const lastNumbers = () => {
  //   const array = Array()
  //     .fill(1)
  //     .map((n, i) => n + i);

  //   return array;
  // };

  // console.log( properties[0].max)

  const selectNumber = number => {
    if (selectedNumbers.length <= properties[0].max) {
      // for (let i = 0; i < selectedNumbers.length; i++) {
      //   if (selectedNumbers[i] === number) {
      //     console.log("same");
      //     toast.error(`Sorry you cannot select one number twice`);
      //     setSelectedNumbers([...selectedNumbers.pop()]);
      //   }
      // }
      if (selectedNumbers?.includes(number)) {
        let arr = selectedNumbers.filter(item => item !== number)
        // console.log(arr);
        setSelectedNumbers(arr)
      } else if (selectedNumbers.length === properties[0].max) {
        toast.error(
          `Sorry you cannot select more than ${properties[0].max} number(s)`
        )
      } else {
        setSelectedNumbers([...selectedNumbers, number])
      }
    } else {
      toast.error(
        `Sorry you cannot select more than ${properties[0].max} number(s)`
      )
    }
  }
  // const clearNumbers = () => {
  //   setSelectedNumbers([])
  // }
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // console.log(num_combs);

  const handleConfirm = () => {
    if (selectedNumbers.length > 0) {
      let comb = combinations(selectedNumbers.length, properties[0].num_comb)
      let payableAmount = comb * amount * properties[0].multiplier
      // console.log(payableAmount);
      const game = {
        name: data?.game[0].product.name,
        mode: properties[0].name,
        id,
        picks: selectedNumbers,
        amount,
        image: data?.game[0].product.image,
        payableAmount,
        gameId: data?.game[0].id
      }

      if (amount === null || amount === 0 || amount < 1) {
        toast.error('Enter a valid amount')
        if (amount > 500) {
          toast.error('Enter an amount between 1 to 500')
        }
      } else {
        if (
          selectedNumbers.length === properties[0].max &&
          selectedNumbers.length === properties[0].min
        ) {
          dispatch(addGame(game))
          navigate('/dashboard/game/add-game')
        } else if (properties[0].min === properties[0].max) {
          toast.error(`Kindly enter ${properties[0].min} number(s)`)
        } else if (selectedNumbers.length < properties[0].min) {
          toast.error(
            `Kindly enter a minimum of ${properties[0].min} number(s)`
          )
        } else if (selectedNumbers.length <= properties[0].max) {
          dispatch(addGame(game))
          navigate('/dashboard/game/add-game')
        }
      }
    } else {
      toast.error('Kindly select some digit options')
    }
  }

  const onChange = e => {
    setAmount(e.target.value)
    if (e.target.value < 0) {
      setAmount('')
    } else {
      setAmount(e.target.value)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='py-11'
    >
      <div className=' md:w-11/12 mx-auto grid lg:grid-cols-8 grid-cols-2 gap-8 n '>
        <div className=' rounded-lg col-span-5 pt-5 lg:py-12 bg-white shadow-lg'>
          <div className='flex-shrink-0 -mt-28 bg-white w-36 mx-auto p-6 rounded-full shadow-lg border-4 border-[#043060] mb-2  '>
            <img
              className='h-20 w-full  object-contain'
              src={gameImage?.url}
              alt='Game Of Day'
            />
          </div>
          <div className='text-center py-1 mb-3 font-extrabold text-lg'>
            <h2 className='text-[#043060]'>{properties[0].hint}</h2>
          </div>

          {/* <div className=" flex mt-6 h-8 justify-center gap-2 flex-wrap">
            {selectedNumbers.map((number) => (
              <p
                key={number}
                className="  flex items-center justify-center text-white font-tommy bg-gradient-to-b from-[#FFAC00] to-[#FF4100] w-8 h-8 rounded-full"
              >
                {number}
              </p>
            ))}
          </div> */}
          <div>
            <div className=' w-full flex flex-col gap-6'>
              {/* <div className="flex items-center justify-end">
                <div className="pr-12">
                  <button
                    onClick={clearNumbers}
                    className="rounded-3xl bg-[#f2f2f2] py-3 px-6 hover: shadow-md "
                  >
                    clear
                  </button>
                </div>
              </div> */}

              <div className='flex flex-col min-h-[600px] '>
                <div className='grid py-3 grid-cols-7 gap-4 md:gap-6 h-[500px] overflow-y-scroll overflow-x-hidden lg:overflow-hidden lg:h-full place-items-center mx-3'>
                  {numbers().map((number, i) => (
                    <button
                      className={classNames(
                        number ===
                          selectedNumbers.find(element => element === number)
                          ? 'bg-gradient-to-b from-[#FFAC00] to-[#FF4100] text-white p-1 w-8 md:w-14 md:h-14'
                          : number === 91
                          ? 'col-start-0 col-span-1'
                          : '',
                        'w-8 md:w-14 md:h-14 rounded-md bg-[#0581ce]/10 border-2 border-[#FF7300]  p-1'
                      )}
                      type='number'
                      key={i}
                      onClick={() => selectNumber(number)}
                    >
                      {number}
                    </button>
                  ))}
                </div>
                <div className='mb-2 lg:hidden flex mt-auto'>
                  <button
                    onClick={e => {
                      setIsOpen(true)
                    }}
                    className=' uppercase font-tommy text-white bg-gradient-to-r from-[#FFAC00] to-[#FF4100] rounded-3xl py-2 px-4 w-full'
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white col-span-3 lg:col-span-3 px-9 py-7 shadow-lg hidden lg:block rounded-lg'>
          <h3 className='text-center font-tommy text-2xl'>
            How much do you want to play?
          </h3>
          <div className=' flex mt-6 h-24 mb-3 justify-center gap-2 flex-wrap overflow-scroll'>
            {selectedNumbers.map((number, key) => (
              <p
                key={key}
                className=' flex items-center justify-center text-white font-tommy bg-gradient-to-b from-[#FFAC00] to-[#FF4100] w-8 h-8 rounded-full'
              >
                {number}
              </p>
            ))}
          </div>
          <div className='my-16 text-xl flex items-center'>
            GHS
            <input
              className=' border-b-2 text-4xl text-center text-black  w-full px-6'
              type='number'
              name='amount'
              onChange={onChange}
              value={amount}
              min='0'
            />
          </div>

          <button
            onClick={handleConfirm}
            className=' uppercase font-tommy text-white bg-gradient-to-r from-[#FFAC00] to-[#FF4100] rounded-3xl py-2 px-4 w-full'
          >
            confirm
          </button>
        </div>
      </div>

      <AmountModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        gameImage={gameImage?.url}
        selectedNumbers={selectedNumbers}
        amount={amount}
        setAmount={setAmount}
        submitMethod={handleConfirm}
        gameName={data?.game[0].product.name}
      />

    </motion.div>
  )
}

export default GamesFive
