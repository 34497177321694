import React from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

// import mega1 from '../assets/images/games/mega-1.png'
// import mega2 from '../assets/images/games/mega-2.png'
// import mega3 from '../assets/images/games/mega-3.png'
// import mega4 from '../assets/images/games/mega-4.png'
// import mega5 from '../assets/images/games/mega-5.png'
// import mega6 from '../assets/images/games/mega-6.png'
// import banker from '../assets/images/games/banker-1.png'
import perm2 from "../assets/images/games/perm-2.png";
import perm3 from "../assets/images/games/perm-3.png";
import perm4 from "../assets/images/games/perm-4.png";
import perm5 from "../assets/images/games/perm-5.png";
import perm6 from "../assets/images/games/perm-6.png";
// import dromo from '../assets/images/games/dromo.png'
// import bingo from '../assets/images/games/bingo.png'
// import happy from '../assets/images/games/mrhappy.png'
// import flow from '../assets/images/games/overflow.png'
// import sokoo from '../assets/images/games/sokooo.png'
// import twadi from '../assets/images/games/twadi.png'
// import cashout from '../assets/images/games/CASHOUT.png'
import moment from "moment";
import { useQuery, gql } from "@apollo/client";

const GET_TODAY_GAME = gql`
query gameOfTheDay($schedule_day: jsonb) {
  game(
    limit: 1
    order_by: [{ schedule_start: asc }, { run_end: asc }]
    where: {
      is_active: { _eq: true }
      schedule_start: { _lte: now }
      schedule_end: { _gte: now }
      product: { schedule_day: { _contains: $schedule_day } }
    }
  ) {
    id
    results
    schedule_start
    schedule_end
    run_start
    run_end
    event_code
    claim_expiry_hours
    is_active
    product {
      id
      name
      image
      description
      slogan
      min_stake_amount
      max_stake_amount
      schedule_start
      schedule_end
      schedule_day
      schedule_type
      is_active
      properties
    }
  }
}
`;
let d = moment().weekday();
let todayDate =
  d === 2
    ? "tue"
    : d === 3
    ? "wed"
    : d === 4
    ? "thu"
    : d === 5
    ? "fri"
    : d === 6
    ? "sat"
    : d === 0
    ? "sun"
    : "mon";

const GamesThree = () => {
  const { data } = useQuery(GET_TODAY_GAME, {
    variables: {
      schedule_day: [todayDate],
    },
  });

  const images = JSON.parse(localStorage.getItem("images"));
  localStorage.setItem("gameData", JSON.stringify(data));

  const gameOfdayImage = images?.data.find(
    (element) => element.file_name === data?.game[0]?.product.image
  );
  const mega1 = images?.data.find(
    (element) => element.file_name === "6e28680072e84a4eb6e4dcbc183e4b35.png"
  );
  const mega2 = images?.data.find(
    (element) => element.file_name === "ff841a5573a24ed0accb057c573cb809.png"
  );
  const mega3 = images?.data.find(
    (element) => element.file_name === "3c4ab99cd2c249bbbf8c8db383acbdc4.png"
  );
  const mega4 = images?.data.find(
    (element) => element.file_name === "438f223939ed4d37bbdcfdb5216ff8a1.png"
  );
  const mega5 = images?.data.find(
    (element) => element.file_name === "9791d74581ee428e80256da39bf88106.png"
  );
  const mega6 = images?.data.find(
    (element) => element.file_name === "c41e160b70a14930b73076a6a3ac0840.png"
  );

  const banker = images?.data.find(
    (element) => element.file_name === "939b359067424fdaa4f88a6d48ce6d9f.png"
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className=" pt-10 "
    >
      {data?.game[0] ? (
        <div className="">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Description list*/}
            <div aria-labelledby="applicant-information-title">
              <div className="bg-transparent flex justify-center">
                <div>
                  <img className="w-48 object-contain rounded-full border-8 border-[#043060] " src={gameOfdayImage?.url} alt="Game of the Day" />
                </div>
              </div>
            </div>
          </div>
          <section
            aria-labelledby="timeline-title"
            className="lg:col-span-1 lg:col-start-3"
          >
            <div className="bg-transparent px-4 py-5 sm:rounded-lg sm:px-6">
              <h2
                id="timeline-title"
                className="text-2xl text-center font-bold text-[#043060]"
              >
                How do you want to play?
              </h2>

              {/* Activity Feed */}
              <div className="flow-root">
                <div className="flex flex-col text-center">
                  <div className=" grid grid-cols-3 lg:grid-cols-4 text-sm  gap-2 mx-auto pt-6">
                    {data.game[0].product.properties.digit_options.map(
                      (item) => (
                        <Link key={item.id} to={`play/${item.id}`}>
                          <button
                            // onClick={() => console.log(item)}
                            className="hover:shadow-lg   border-black border-2 md:w-52 w-24 rounded-md"
                          >
                            <img
                              className=" w-full object-contain"
                              src={
                                item.image ===
                                "6e28680072e84a4eb6e4dcbc183e4b35.png"
                                  ? mega1?.url
                                  : item.image ===
                                    "ff841a5573a24ed0accb057c573cb809.png"
                                  ? mega2?.url
                                  : item.image ===
                                    "3c4ab99cd2c249bbbf8c8db383acbdc4.png"
                                  ? mega3?.url
                                  : item.image ===
                                    "438f223939ed4d37bbdcfdb5216ff8a1.png"
                                  ? mega4?.url
                                  : item.image ===
                                    "9791d74581ee428e80256da39bf88106.png"
                                  ? mega5?.url
                                  : item.image ===
                                    "c41e160b70a14930b73076a6a3ac0840.png"
                                  ? mega6?.url
                                  : item.image ===
                                    "1db75c04c11840e9afa6fed1f80dc422.png"
                                  ? perm2
                                  : item?.image ===
                                    "5f9af255b5704b6595f9cd70ee3c6899.png"
                                  ? perm3
                                  : item?.image ===
                                    "cf8b475a702a4179b3242908ce374ff8.png"
                                  ? perm4
                                  : item?.image ===
                                    "72f3407b6efe4cf59a39fe25a845847d.png"
                                  ? perm5
                                  : item?.image ===
                                    "61f8b3d2ecae4ffd93fbef3ecfcf1c60.png"
                                  ? perm6
                                  : item?.image ===
                                    "939b359067424fdaa4f88a6d48ce6d9f.png"
                                  ? banker?.url
                                  : ""
                              }
                              alt="Mega Fortune Lottery"
                            />
                          </button>
                        </Link>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className=" text-4xl text-center py-56">
          Sorry there no games today
        </div>
      )}
    </motion.div>
  );
};

export default GamesThree;