import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import logoWhite from '../../assets/images/logos/Mega Fortune Lottery Full color.png'
import menuImg from '../../assets/images/Spark-menu-image.png'
import logo2 from '../../assets/images/logos/Mega Fortune Lottery Logo Dark.png'
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom'
import { FiMenu, FiX } from 'react-icons/fi'
import { HiOutlineHome, HiOutlineTicket, HiUserCircle } from 'react-icons/hi'
// import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from '../../components/LogOutButton'
import { useQuery, gql } from '@apollo/client'
// import { useDispatch } from 'react-redux'
// import { getUserDetails } from '../../auth/userSlice'

const GET_USER_DETAILS = gql`
  query userDetails($id: uuid = "") {
    customer_by_pk(id: $id) {
      id
      address
      device_id
      digital_address
      email
      first_name
      full_name
      ghana_card
      is_active
      last_name
      middle_name
      national_id
      phone_number
      region_id
      selfie
      status
      town_city
      updated_at
      updated_by
      user_type
    }
  }
`

const GET_USER_TYPE = gql`
  query getUserType($_userID: uuid) {
    customer(where: { id: { _eq: $_userID } }) {
      user_type
    }
  }
`

const navigation = [
  { name: 'Home', href: ' ' },
  { name: 'Games History', href: 'history' },
  { name: 'Account', href: 'account' }
]
// const userNavigation = [{ name: 'Your Profile', href: '/dashboard/account' }]

// function classNames (...classes) {
//   return classes.filter(Boolean).join(' ')
// }
// const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const Index = () => {
  const navigate = useNavigate()
  // const { getAccessTokenSilently } = useAuth0();
  // const dispatch = useDispatch();
  const [userId, setUserId] = useState('')
  const [currentNavItem, setCurrentNavItem] = useState('Home')
  // const [user, setUser] = useState(null);

  // let user
  // let userInitials

  useEffect(() => {
    // const getUser = async () => {
    //   try {
    //     // const res = await dispatch(getUserDetails());
    //   const res = await localStorage.getItem("customer_id")
    //   console.log(res)
    //   setUserId(res);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };
    // getUser();
    setUserId(localStorage.getItem('customer_id'))
  }, [])

  const { data: today } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: userId
    }
  })

  const { data: customer } = useQuery(GET_USER_TYPE, {
    variables: {
      _userID: userId
    }
  })

  if (today) {
    localStorage.setItem(
      'user',
      JSON.stringify({
        data: today.customer_by_pk
      })
    )

  //  let user = JSON.parse(localStorage.user)
    // userInitials =
    //   user?.data?.first_name?.charAt(0) + user?.data.last_name?.charAt(0)
    // setUser(JSON.parse(localStorage.user))
  }

  useEffect(() => {
    if (customer) {
      if (
        localStorage.getItem('kyc') === 'kyc_required' &&
        customer[0]?.user_type === 'agent'
      ) {
        navigate('/dashboard/account')
      } else {
        // this.uDetails();
      }
    }
  }, [today, navigate, customer])

  // const { user } = useAuth0();

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 lg:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div
                style={{ backgroundImage: `url(${menuImg})` }}
                className='fixed inset-0 bg-[#043060] md:w-80 w-full bg-no-repeat bg-bottom bg-cover'
              />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex w-full'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full flex-1 flex-col pt-5 pb-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute z-30 top-0 mb-6  px-4 py-4'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center'
                        onClick={e => {
                          e.preventDefault()
                          setSidebarOpen(false)
                        }}
                      >
                        <span className='text-white'>
                          <FiX className='h-20' />
                        </span>
                      </button>
                    </div>
                  </Transition.Child>

                  <div className='flex flex-col h-full w-full md:w-80 absolute'>
                    <div className='mt-10'>
                      <nav className=''>
                        {navigation.map(item => (
                          <NavLink
                            onClick={e => {
                              e.preventDefault()
                              setCurrentNavItem(item.name)
                              navigate(item.href)
                              setSidebarOpen(false)
                            }}
                            key={item.name}
                            // to={item.href}
                            // className={({ isActive }) =>
                            //   classNames(
                            //     isActive
                            //       ? 'bg-indigo-800 text-white'
                            //       : 'text-indigo-100 hover:bg-indigo-600',
                            //     'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            //   )
                            // }

                            className={
                              currentNavItem === item.name
                                ? 'bg-[#022345] text-white group flex items-center px-2 py-1 text-lg mt-6 font-medium rounded-md'
                                : 'text-indigo-100 hover:bg-[#022345]/30 group flex items-center px-2 py-1 text-lg mt-6 font-medium rounded-md'
                            }
                          >
                            {item.name === 'Home' ? (
                              <HiOutlineHome />
                            ) : item.name === 'Account' ? (
                              <HiUserCircle />
                            ) : (
                              <HiOutlineTicket />
                            )}
                            <span> &nbsp; &nbsp; {item.name}</span>
                          </NavLink>
                        ))}
                      </nav>
                      <div className='w-full bg-cover bg-no-repeat flex-grow my-auto'></div>
                    </div>
                    <div className='pl-4 text-indigo-100 bg-[#022345] flex flex-row items-center mt-auto relative left-0 bottom-3'>
                      <LogoutButton />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              {/* <div className="w-14 flex-shrink-0" aria-hidden="true">
                // Dummy element to force sidebar to shrink to fit close icon
              </div> */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed md:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex flex-grow flex-col overflow-y-auto bg-[#043060] pt-5'>
            <div className='flex flex-shrink-0 items-center px-4'>
              <div className='text-2xl bg-white py-4 px-12 rounded-md shadow-md cursor-pointer flex items-center'>
                <Link to='/'>
                  <img className='h-10' src={logo2} alt='Mega Fortune Lottery Logo' />
                </Link>
              </div>
            </div>
            <div className='mt-5 flex flex-1 flex-col'>
              <nav className='flex-1 space-y-3 px-2 pb-4'>
                {navigation.map(item => (
                  <NavLink
                    onClick={e => {
                      e.preventDefault()
                      setCurrentNavItem(item.name)
                      navigate(item.href)
                    }}
                    key={item.name}
                    // to={item.href}
                    className={
                      currentNavItem === item.name
                        ? 'bg-[#022345] text-white group flex items-center px-2 py-1 text-lg mt-6 font-medium rounded-md'
                        : 'text-indigo-100 hover:bg-[#022345]/30 group flex items-center px-2 py-1 text-lg mt-6 font-medium rounded-md'
                    }
                  >
                    {item.name === 'Home' ? (
                      <HiOutlineHome />
                    ) : item.name === 'Account' ? (
                      <HiUserCircle />
                    ) : (
                      <HiOutlineTicket />
                    )}
                    <span className='px-4 py-1'>{item.name}</span>
                  </NavLink>
                ))}
              </nav>
              <div
                style={{ backgroundImage: `url(${menuImg})` }}
                className='w-full h-full bg-cover bg-no-repeat'
              ></div>
              <div className='relative pl-4 py-6 text-indigo-100 hover:bg-[#022345]/30 flex flex-row items-center'>
                <LogoutButton />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-1 flex-col lg:pl-64'>
          <div className='sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white'>
            <button
              type='button'
              className='px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='text-black'>
                <FiMenu />
              </span>
            </button>
            {currentNavItem === 'Home' ? (
              <div className='lg:hidden flex items-center pl-3'>
                <img className='h-5' src={logo2} alt='Mega Fortune Lottery' />
              </div>
            ) : currentNavItem === 'Account' ? (
              <div className='flex items-center pl-3'>
                <h4 className='font-semibold text-lg'>Account</h4>
              </div>
            ) : (
              <div className='flex items-center pl-3'>
                <h4 className='font-semibold text-lg'>Game History</h4>
              </div>
            )}
            {/* <div className='flex flex-1 justify-between items-center px-4'>
              <p className=' text-2xl font-tommy text-[#043060]'>Dashboard</p>
              <div className='ml-4 flex items-center md:ml-6'>
                <button
                  type='button'
                  className='rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  <span className='sr-only'>View notifications</span>
                </button>

                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                      <span className='sr-only'>Open user menu</span>
                      {user ? (
                        <span className='border-2 border-white rounded-full px-3 py-3 bg-slate-600 text-white'>
                          {userInitials}
                        </span>
                      ) : (
                        <div></div>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {userNavigation.map(item => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link to={item.href}>
                              <div
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 w-full py-2 text-sm text-gray-700'
                            )}
                          >
                            <LogoutButton />
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div> */}
          </div>

          <main>
            <div className='py-6 bg-gray-50'>
              {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Dashboard
                </h1>
              </div> */}
              <div className=' mx-5 max-w-[90%]'>
                {/* Replace with your content */}
                <Outlet />
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default Index
